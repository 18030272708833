module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"760a3c8d939b616979b988aa275801fc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pt","en"],"defaultLanguage":"en","siteUrl":"https://splitcounter.app/","i18nextOptions":{"interpolation":{"escapeValue":false},"detection":{"order":["navigator","htmlTag","path","subdomain"],"caches":["localStorage"]}},"pages":[{"matchPath":"/:lang?/sara-e-ricardo/","languages":["pt","en"]}],"translationPath":"/vercel/path0/src/locales","defaultNS":"common"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
